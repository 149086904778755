import React from "react";

function Footer() {
  return (
    <div className="footer text-center border-top pt-3">
      <p>&copy; Santiago Pulido Peláez 2020</p>
    </div>
  );
}

export default Footer;
